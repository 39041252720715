import React from "react";
import "./userBuisnessModal.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import { useEffect } from "react";
import { callApi } from "../../api/apiCaller";
import { useState } from "react";
import routes from "../../api/routes";
import moment from "moment/moment";
import Loader from "../../components/loader/loader";
import DescriptionModal from "../../components/descriptionBuisnessModal/descriptionModal";
import { crossIcon, homeIcon, redTrash, trueIcon } from "../../assets";
import { List } from 'antd';
import NestedDescription from "../nestedDescription/nestedDescription";

const UserBuisnessModal = ({ showModal, setShowModal, item }) => {
    console.log("items in modal page",item)
    // console.log("image",item?.profileImage?.props.children.props.src)

    const [isloading, setIsLoading] = useState(false);
    const [buisnessofUser,setBuisnessofUser] = useState([{}])
    const [showModalDes, setShowModalDes] = useState(false);
    const [pDescription, setPdescription] = useState('');
    const [buisnessProducts,setBuisnessProducts] = useState([])
    const [buisnessServices,setBuisnessServices] = useState([])


    // console.log("chek user buisness",buisnessofUser)

    const getAllBuisnessofUser = () => {
        let getRes = (res) => {
          console.log("res of buisness of a user ", res);
          setBuisnessofUser(res?.data?.buisness)
        };

        // console.log("calling id",item.key) 

        callApi("GET", `${routes.getAllBuissnessOfAUser}/${item.key}`, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };

      const getAllBuisnessProductAndService = () => {
        let getRes = (res) => {
          console.log("res of buisness Products And Services ", res?.data?.buisnessProductAndService);
          const products = res?.data?.buisnessProductAndService.filter(item => item.objType === "product");
          console.log("product",products)
          setBuisnessProducts(products)
          const services = res?.data?.buisnessProductAndService.filter(item => item.objType === "service");
          console.log("services",services)
          setBuisnessServices(services)
          // setBuisnessofUser(res?.data?.buisnessProductAndService)
        };

        callApi("GET", `${routes.businessProductsAndServices}/${item.key}`, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };


      console.log("businessProducts",buisnessProducts)
      console.log("buisnessServices",buisnessServices)


    useEffect(()=>{
        getAllBuisnessofUser()
        getAllBuisnessProductAndService()
    },[item.key])

    const columns = [
        {
          title: "Name",
          dataIndex: "Name",
          className: "role-name-column-header",
        },
        {
          title: "Email",
          dataIndex: "email",
          align: "center",
          className: "type-name-column-header",
          width: 400,
        },
        {
            title: "Business Address",
            dataIndex: "buisnessAddress",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Location",
          dataIndex: "locationAddress",
          align: "center",
          className: "action-column-header",
      },
        {
          title: "Business Description",
          dataIndex: "buisnessdescription",
          align: "center",
          className: "action-column-header",
        },
        {
            title: "Contact",
            dataIndex: "buisnessNumber",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Profile Picture",
          dataIndex: "profilePicture",
          align: "center",
          className: "action-column-header",
        },
        {
          title: "Delievry Options",
          dataIndex: "delieveryOptions",
          align: "center",
          className: "action-column-header",
        },
      ];

      const data = buisnessofUser?.map((item, index) => {
        const deliveryoptions = item?.youOffer;
        console.log("all item contsact number ??????????",item.phoneNumber)
        return {
          key: item._id,
          Name: item?.businessName,
          email: item?.email,
          buisnessAddress:(
            <div>
            <p style={{ fontSize: "12px" }}>
              {item?.locationText && item?.locationText.length > 5
                ? item?.locationText.substring(0, 5) + "..."
                : item?.locationText}{" "}
              <span
                onClick={() => {
                  setShowModalDes(true);
                  setPdescription(item?.businessAddress);
                }}
                style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
              >
                {" "}
                See More{" "}
              </span>
            </p>
          </div>
          ),
          // item?.businessAddress,
          locationAddress:(
            <div>
              <p style={{ fontSize: "12px" }}>
                {item?.locationText && item?.locationText.length > 5
                  ? item?.locationText.substring(0, 5) + "..."
                  : item?.locationText}{" "}
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.locationText);
                  }}
                  style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                >
                  {" "}
                  See More{" "}
                </span>
              </p>
            </div>
            // item?.locationText
          ),
          buisnessdescription: (
            <div>
              <p style={{ fontSize: "12px" }}>
                {item?.businessDescription && item?.businessDescription.length > 5
                  ? item?.businessDescription.substring(0, 10) + "..."
                  : item?.businessDescription}{" "}
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.businessDescription);
                  }}
                  style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                >
                  {" "}
                  See More{" "}
                </span>
              </p>
            </div>
          ),
        // buisnessdescription: item?.businessDescription,
          buisnessNumber:item?.phoneNumber,
          profilePicture: (
            <div className="product-list-image">
              <Image onClick={(e)=>e.stopPropagation()} width={50} height={40} src={item?.workSpacePhoto} alt="profile-image" />
            </div>
          ),
          delieveryOptions:(
            <div>
            <List
            dataSource={deliveryoptions}
            renderItem={(option, index) => (
            <List.Item key={index}>{option}</List.Item>
            )}
            bordered
            size="small"
            />
            </div>
          )
          // delieveryOptions: item?.youOffer?.map((ind,item)=>{
          //  return <li key={ind}>{ind}</li>
          // })
        };
      });

      const columnsProductd = [
        {
          title: "Name",
          dataIndex: "productName",
          className: "role-name-column-header",
        },
        {
          title: "Description",
          dataIndex: "productbuisnessdescription",
          align: "center",
          className: "action-column-header",
        },
        {
            title: "Price",
            dataIndex: "productbuisnessPrice",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Picture",
          dataIndex: "productprofilePicture",
          align: "center",
          className: "action-column-header",
        },
        {
          title: "HST",
          dataIndex: "hstAmount",
          align: "center",
          className: "action-column-header",
        },
      ];

      const dataProducts = buisnessProducts?.map((item, index) => {
        let hasHST = item?.chargeHST ? `${item?.HSTAmount} %` : "false";
        console.log("image ",item?.images[0].url)
        return {
          key: item._id,
          productName: item?.name,
          productbuisnessdescription:(
          <div>
          <p style={{ fontSize: "12px" }}>
            {item?.description && item?.description.length > 5
              ? item?.description.substring(0, 10) + "..."
              : item?.description}{" "}
            <span
              onClick={() => {
                setShowModalDes(true);
                setPdescription(item?.description);
              }}
              style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
            >
              {" "}
              See More{" "}
            </span>
          </p>
        </div>
          ),
          // item?.description,
          productbuisnessPrice:item?.price,
          productprofilePicture: (
            <div className="product-list-image">
              <Image onClick={(e)=>e.stopPropagation()} width={50} height={40} src={item?.images[0]?.url} alt="profile-image" />
            </div>
          ),
          hstAmount: hasHST,
        };
      });


      const columnsServices = [
        {
          title: "Name",
          dataIndex: "productName",
          className: "role-name-column-header",
        },
        {
          title: "Description",
          dataIndex: "productbuisnessdescription",
          align: "center",
          className: "action-column-header",
        },
        {
            title: "Price",
            dataIndex: "productbuisnessPrice",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Picture",
          dataIndex: "productprofilePicture",
          align: "center",
          className: "action-column-header",
        },
        {
          title: "HST",
          dataIndex: "hstAmount",
          align: "center",
          className: "action-column-header",
        },
      ];

      const dataServices = buisnessServices?.map((item, index) => {
        let hasHST = item?.chargeHST ? `${item?.HSTAmount} %` : "false";
        console.log("image ",item?.images[0].url)
        return {
          key: item._id,
          productName: item?.name,
          productbuisnessdescription:(
          <div>
          <p style={{ fontSize: "12px" }}>
            {item?.description && item?.description.length > 5
              ? item?.description.substring(0, 10) + "..."
              : item?.description}{" "}
            <span
              onClick={() => {
                setShowModalDes(true);
                setPdescription(item?.description);
              }}
              style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
            >
              {" "}
              See More{" "}
            </span>
          </p>
        </div>
          ),
          // item?.description,
          productbuisnessPrice:item?.price,
          productprofilePicture: (
            <div className="product-list-image">
              <Image onClick={(e)=>e.stopPropagation()} width={50} height={40} src={item?.images[0]?.url} alt="profile-image" />
            </div>
          ),
          hstAmount: hasHST,
        };
      });


      const getRowClassName = (record, index) => {
        if (index % 2 === 0) {
          return "server-role-even-row";
        }
        return "server-role-odd-row";
      };


    return (
    <div
      className="add-product-modal-main-container"
    >
     {showModalDes && (
        <NestedDescription
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
     <Loader loading={isloading} />
      <div
        style={{ marginTop: "30rem", width: "150rem" , height:"100rem", overflow:"scroll"}}
        className="add-product-modal-container product-description-detail"
      >
      <div style={{display:"flex",justifyContent:"space-between"}}>
        <h1>User Details</h1>
        <button style={{backgroundColor:"#ffffff", border:"none",cursor: "pointer"}} onClick={() => setShowModal(false)}>
        <img src={crossIcon} alt="home-icon" />
        </button>
      </div>
        <h2>{item?.Name}</h2>
        <h2>{item?.email}</h2>    
      <div className="configure-server-roles-main-heading-container">
        <h1>User Business Details</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>{e.stopPropagation()}}
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table-buisneessModal"
      ></Table>
      </div>
      <div className="configure-server-roles-main-heading-container">
        <h1>User Business Products Details</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>{e.stopPropagation()}}
          rowClassName={getRowClassName}
          columns={columnsProductd}
          dataSource={dataProducts}
          pagination={true}
          className="subscriptionapi-table-buisneessModal"
      ></Table>
      </div>
      <div className="configure-server-roles-main-heading-container">
        <h1>User Business Service Details</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>{e.stopPropagation()}}
          rowClassName={getRowClassName}
          columns={columnsServices}
          dataSource={dataServices}
          pagination={true}
          className="subscriptionapi-table-buisneessModal"
      ></Table>
      </div>
    </div>
    </div>
  );
};

export default UserBuisnessModal;

// export const BASE_URL = "http://localhost:8080";
export const BASE_URL = "https://allthingslocal.ca";



export default {
  // -----AUTH------//
  // signUp: BASE_URL + "/user/signup",
  signIn: BASE_URL + "/api/auth/login/admin",
  // sendOTP: BASE_URL + "/user/sendOTP",
  // verifyOTP: BASE_URL + "/user/verify",
  // forgotPassword: BASE_URL + "/user/forgotPassword",
  // resetPassword: BASE_URL + "/user/resetPassword",
  // verifyOTPresetPassword: BASE_URL + "/user/verifyOTPResetPassword",
  logOut: BASE_URL + "/api/auth/login/admin/logout",
  // updateUser: BASE_URL + "/user",


  
  // -----------Products--------------//
  getProducts: BASE_URL + "/api/auth/login/admin/allproducts",
  updateProduct: BASE_URL + "/api/auth/login/admin/updateProductorService",
  productBusiness: BASE_URL + "/api/auth/login/admin/productBusiness",
  // upDateProduct: BASE_URL + "/product/update",
  // createProduct: BASE_URL + "/product/create",
  // deleteProduct: BASE_URL + "/product/delete",
  // allBookedProduct: BASE_URL + "/booking/allbookedproducts",
  productOrders: BASE_URL + "/api/auth/login/admin/allorders",
  specificProductOrders: BASE_URL + "/api/auth/login/admin/specificOrdersDetials",
  businessProductsAndServices : BASE_URL + "/api/auth/login/admin/singleuserAllBuisnessesProductAndServices",
  updateProductPaymentStatus: BASE_URL + "/api/auth/login/admin/orderUpdate",

  // -------------Services-------------//
  getAllServices: BASE_URL + "/api/auth/login/admin/allproducts",
  updateService: BASE_URL + "/api/auth/login/admin/updateProductorService",
  serviceBusiness: BASE_URL + " /api/auth/login/admin/productBusiness",

  // createService: BASE_URL + "/service/create",
  // deleteService: BASE_URL + "/service/delete",
  // updateService: BASE_URL + "/service/update",
  // allBookedServices: BASE_URL + "/booking/allbookedservices",
  serviceOrders: BASE_URL + "/api/auth/login/admin/allorders",
  specificServiceOrder: BASE_URL + "/api/auth/login/admin/specificOrdersDetials",
  updateServicePaymentStatus: BASE_URL + "/api/auth/login/admin/orderUpdate",

  

  // -------------Users-------------//
  getAllUser: BASE_URL + "/api/auth/login/admin/allusers",
  blockOrUnblockUser: BASE_URL + "/api/auth/login/admin/userUpdate",
  deleteUser:BASE_URL + "/api/auth/login/admin/userUpdate",
  getAllBuissnessOfAUser: BASE_URL + "/api/auth/login/admin/singleuserallbuisnesses",

};

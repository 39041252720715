import React, { useState, useEffect } from "react";
import "./userList.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import { crossIcon, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import UserBuisnessModal from "../../components/userBuisnessModal/userBuisnessModal";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";


const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  // const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedBuisness, setSelectedBuisness] = useState({});
  const [showModal,setShowModal] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [pDescription, setPdescription] = useState("");

  const getAllUser = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      let user = res?.data?.user.filter(user=>user.role=="user") 
      console.log("user",user)
      setUsers(user);
    };

    callApi("GET", routes.getAllUser, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  let blocked = async (ind, event, id, blockStatus) => {
    event.stopPropagation();
    let i = ind;
    console.log("id is", id, ind);
    let data = {
      id: id,
      isBlocked: blockStatus,
    };

    let getRes = (res) => {
      console.log("res of user list", res);
      if (res) {
        users[i] = res?.data?.user;
      }
    };
    console.log("sendingData", data);
     callApi(
      "PATCH",
      routes.blockOrUnblockUser,
      data,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  let deleted = async (ind, event, id, deleteStatus) => {
    event.stopPropagation();
    let i = ind;
    console.log("id is", id, ind);
    let data = {
      id: id,
      isDeleted: deleteStatus,
    };

    let getRes = (res) => {
      console.log("res of user list", res);
      if (res) {
        users[i] = res?.data?.user;
      }
    };
    console.log("sendingData", data);
     callApi(
      "PATCH",
      routes.deleteUser,
      data,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  

  useEffect(() => {
    getAllUser();
  }, []);



  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      className: "role-name-column-header",
      width:200,
    },
    // {
    //   title: "Last Name",
    //   dataIndex: "lastName",
    //   className: "role-name-column-header",
    // },
    {
      title: "Email",
      dataIndex: "email",
      className: "role-name-column-header",
      width: 300,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "dob",
      align: "center",
      className: "role-name-column-header",
    },
    {
      title: "Location",
      dataIndex: "location",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Address",
      dataIndex: "address",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Block/Unblock",
      dataIndex: "blockORUnblock",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Delete",
      dataIndex: "deleteUser",
      align: "center",
      className: "action-column-header",
    },
  ];

  const data = users?.map((item, index) => {
    return {
      key: item._id,
      Name: item?.name,
      // lastName: item?.lastname,
      email: item?.email,
      dob: moment(item?.dob).format("MM-DD-YYYY"),
      address:
      (
        <div>
        {item?.address?.map((ad, index) => (
          <p key={index}>
            {ad?.name?.length > 3 ? ad?.name?.substring(0, 3) + "..." : `${ad?.name} `}
            {index < 5 && (
              <span
                onClick={() => {
                  setShowModalDes(true);
                  setPdescription(ad?.name);
                }}
                style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
              >
                See More
              </span>
            )}
          </p>
        ))}
      </div>
      ),
      location: (
        <div>
          {item?.address?.map((ad, index) => (
            <p key={index}>
              {ad?.addressDesc?.length > 3
                ? ad?.addressDesc?.substring(0, 3) + "..."
                : `${ad?.addressDesc} `}
              {index < 5 && (
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(ad?.addressDesc);
                  }}
                  style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                >
                  See More
                </span>
              )}
            </p>
          ))}
        </div>
      ),
      // item?.address?.map((i,ad) => {
      //   return <p key={i}>{i.name}</p>;
      // }),   
      profilePicture: (
        <div className="product-list-image">
          <Image onClick={(e)=>e.stopPropagation()} width={50}  height={50} src={item?.profileImage} alt="profile-image" />
        </div>
      ),
      // verified: (
      //   <div className="server-roles-trash-btn">
      //     <img src={item?.verified ? trueIcon : crossIcon} alt="" />
      //   </div>
      // ),
      blockORUnblock: (
        <div className="server-roles-trash-btn">
          {item.isBlocked ? (
            <Button
              className="server-roles-trash-btn"
              style={{
                width: "80px",
                color:"white",
                backgroundColor: "#52c41a",
                // borderColor: "#f5222d",
              }}
              // style={{ width: '80px' }}
              // type="primary"
              onClick={async (event) => blocked(index, event, item._id, false)}
            >
              Unblock
            </Button>
          ) : (
            <Button
              className="server-roles-trash-btn"
              style={{
                width: "80px",
                color:"white",
                backgroundColor: "#f5222d",
                // borderColor: "#52c41a",
              }}
              // style={{ width: '80px' }}
              onClick={async (event) => blocked(index, event, item?._id, true)}
            >
              Block
            </Button>
          )}
        </div>
      ),
      deleteUser: (
        <div className="server-roles-trash-btn">
          {item.isDeleted ? (
            <Button
              className="server-roles-trash-btn"
              style={{
                width: "80px",
                color:"white",
                backgroundColor: "#52c41a",
                // borderColor: "#f5222d",
              }}
              // style={{ width: '80px' }}
              // type="primary"
              onClick={async (event) => deleted(index, event, item._id, false)}
            >
              Undo
            </Button>
          ) : (
            <Button
              className="server-roles-trash-btn"
              style={{
                width: "80px",
                color:"white",
                backgroundColor: "#f5222d",
                // borderColor: "#52c41a",
              }}
              // style={{ width: '80px' }}
              onClick={async (event) => deleted(index, event, item?._id, true)}
            >
              Delete
            </Button>
          )}
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  
  const handleRowClick = (record) => {
    console.log("record", record)
    setShowModal(true);
    // setSelectedRowKey(record.key);
    setSelectedBuisness(record)
  };

  console.log("record object",selectedBuisness)

  // useEffect(() => {
  //   if (selectedRowKey !== null) {
  //     alert(`Row ${selectedRowKey} clicked!  ${selectedBuisness[0].Name}`);
  //     setSelectedRowKey(null); // Reset the selected row key
  //   }
  // }, [selectedRowKey]);

  return (
    <div className="admin-products-main-container">
     {/* {
    showModal && (
    <UserBuisnessModal 
          showModal={showModal}
          setShowModal={setShowModal}
          item={selectedBuisness} 
    />
    )
  } */}
  {showModalDes && (
        <DescriptionModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>User List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>User List</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table-userList"
          onRow={(record) => ({
          onClick: (e) => {
            e.stopPropagation();
           handleRowClick(record)
           },
          })}
        ></Table>
      </div>
    </div>
  );
};

export default UserList;

import React, { useState, useEffect } from "react";
import "./serviceOrder.css";


import { Breadcrumb, Button, Select, Table, Space,Image } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { crossIcon, homeIcon, options, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import moment from "moment";
import Loader from "../../components/loader/loader";
import OrderModal from "../../components/orderModal/orderModal";


const ServiceOrder = () => {
  const [bookedServices, setBookedServices] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [showModalOption, setShowModalOption] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showModal,setShowModal] = useState(false);
  const getAllServicesBooked = () => {
    let getRes = (res) => {
      console.log("res of booked services", res);
      let orderService = res.data.orders.filter(order=>order.type=="booking")
      console.log("data cheking objeType service",orderService)
      setBookedServices(orderService);
    };

    callApi(
      "GET",
      routes.serviceOrders,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };


  let paymentStatusUpdate = async (ind, event, id, payStatus) => {
    event.stopPropagation();
    let i = ind;
    console.log("id is", id, ind);
    let data = {
      id: id,
      paymentStatus: payStatus,
    };

     let getRes = (res) => {
      console.log("res of user list", res);
      if (res) {
        bookedServices[i].paymentStatus = res?.data?.user?.paymentStatus;
      }
    };

    console.log("sendingData", data);

     callApi(
      "PATCH",
      routes.updateServicePaymentStatus,
      data,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "name",
      className: "role-name-column-header",
    },
    {
      title: "Client Image",
      dataIndex: "profileImage",
      className: "role-name-column-header",
    },
    {
      title: "Client Email",
      dataIndex: "email",
      // align: "center",
      className: "type-name-column-header",
        width: 300,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Service Title",
      dataIndex: "serviceTitle",
      className: "action-column-header",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Price",
      dataIndex: "Price",
      align: "center",
      className: "action-column-header",
    },
    // {
    //   title: "Options",
    //   dataIndex: "options",
    //   align: "center",
    //   className: "action-column-header",
    // },
    {
      title: "IsPaid",
      dataIndex: "ispaid",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      className: "action-column-header",
      width: 300,
      sorter: (a, b) =>
      moment(a.date, "DD-MM-YYYY").unix() -
      moment(b.date, "DD-MM-YYYY").unix(),
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "action-column-header",

      filters: [
        {
          text: "New",
          value: "new",
        },
        {
          text: "Accepted",
          value: "accepted",
        },
        {
          text: "ReadyForPickup",
          value: "readyForPickup",
        },
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Decline",
          value: "decline",
        },
        {
          text: "Cancel",
          value: "cancel",
        },
        {
          text: "Delivered",
          value: "delivered",
        },
     
        
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];

  const data = bookedServices?.map((item,ind) => {
    return {
      key:item._id,
      name: `${item?.customer?.name}`,
      profileImage:
      (
        <div className="product-list-image">
          <Image onClick={(e)=>e.stopPropagation()} width={50} src={item?.customer?.profileImage}/>
        </div>
      ), 
      email: item?.customer?.email,
      serviceTitle: item?.service?.name,
      orderNo: item?.recieptId,
      Price: `$${item?.amount}`,
      ispaid: (
        <div className="server-roles-trash-btn">
          {item?.paymentStatus ? (
            <img onClick={(event) => paymentStatusUpdate(ind, event, item._id, false)} src={trueIcon} alt="Paid" />
          ) : (
            <img onClick={(event) => paymentStatusUpdate(ind, event, item._id, true)} src={crossIcon} alt="Unpaid" />
          )}
        </div>
      ),
      date:moment(item?.createdAt).format("DD-MM-YYYY"),
      status: item?.status,
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  const handleRowClick = (record) => {
    console.log("record", record)
    setShowModal(true);
    // setSelectedRowKey(record.key);
    setSelectedOrder(record)
  };

  console.log("record object",selectedOrder)

  useEffect(() => {
    getAllServicesBooked();
  }, []);
  return (
    <div className="admin-products-main-container">
      {
    showModal && (
    <OrderModal 
          showModal={showModal}
          setShowModal={setShowModal}
          item={selectedOrder} 
    />
    )
  }
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Services booking List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Service Order</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>e.stopPropagation()}
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table-serviceOrder"
          onRow={(record) => ({
          onClick: (e) => {
            e.stopPropagation();
            handleRowClick(record)},
          })}
        ></Table>
      </div>
    </div>
  );
};

export default ServiceOrder;

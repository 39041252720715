import React from "react";
import "./orderModal.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import { useEffect } from "react";
import { callApi } from "../../api/apiCaller";
import { useState } from "react";
import routes from "../../api/routes";
import moment from "moment/moment";
import Loader from "../loader/loader";
import DescriptionModal from "../descriptionBuisnessModal/descriptionModal";
import { crossIcon, homeIcon, redTrash, trueIcon } from "../../assets";
import { List } from 'antd';
import NestedDescription from "../nestedDescription/nestedDescription";

const OrderModal = ({ showModal, setShowModal, item }) => {
    console.log("items in order modal page",item)
    // console.log("image",item?.profileImage?.props.children.props.src)

    const [isloading, setIsLoading] = useState(false);
    const [specificOrderDetails,setSpecificOrderDetails] = useState([{}])
    const [showModalDes, setShowModalDes] = useState(false);
    const [pDescription, setPdescription] = useState('');

    const getDetailsOfaOrder = () => {
        let getRes = (res) => {
          console.log("res of details of a order ", res);
          setSpecificOrderDetails(res?.data?.orders)
        };

        console.log("calling id",item.key) 

        callApi("GET", `${routes.specificProductOrders}/${item.key}`, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };


    useEffect(()=>{
      getDetailsOfaOrder()
    },[item.key])



    console.log("chek order details",specificOrderDetails)
    console.log("chek order details of Owner of buisness",specificOrderDetails[0])
    console.log("chek order details of buisness",specificOrderDetails[0].business)



    const columnsOfOwnerDetails = [
        {
          title: "Name",
          dataIndex: "Name",
          className: "role-name-column-header",
        },
        {
          title: "Email",
          dataIndex: "email",
          align: "center",
          className: "type-name-column-header",
          width: 400,
        },
        {
          title: "Role",
          dataIndex: "role",
          align: "center",
          className: "action-column-header",
        },
        {
            title: "Subsciption Type",
            dataIndex: "subscription",
            align: "center",
            className: "action-column-header",
        },
        // {
        //   title: "Buisness Description",
        //   dataIndex: "buisnessdescription",
        //   align: "center",
        //   className: "action-column-header",
        // },
        {
            title: "Contact",
            dataIndex: "buisnessNumber",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Profile Picture",
          dataIndex: "profilePicture",
          align: "center",
          className: "action-column-header",
        },
      ];

      const dataOfOwnerDetails = specificOrderDetails?.map((item, index) => { 
        const role = item?.businessOwner?.role;
        const hasSubscription = item?.businessOwner?.hasSubscription;
        return {
          key: item?.businessOwner?._id,
          Name: item?.businessOwner?.name,
          email: item?.businessOwner?.email,
          role: role,
          subscription: role == "businessuser" ? (hasSubscription ? "Premium" : "Regular") : "Nill",
          // subscription:item?.businessOwner?.hasSubscription? "Premium" : "Regular",
          // buisnessdescription: (
          //   <div>
          //     <p style={{ fontSize: "12px" }}>
          //       {item?.businessDescription && item?.businessDescription.length > 5
          //         ? item?.businessDescription.substring(0, 10) + "..."
          //         : item?.businessDescription}{" "}
          //       <span
          //         onClick={() => {
          //           setShowModalDes(true);
          //           setPdescription('fghfhgfhfh');
          //         }}
          //         style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
          //       >
          //         {" "}
          //         See More{" "}
          //       </span>
          //     </p>
          //   </div>
          // ),
        // buisnessdescription: item?.businessDescription,
          buisnessNumber:item?.businessOwner?.phoneNumber,
          profilePicture: (
            <div className="product-list-image">
              <Image onClick={(e)=>e.stopPropagation()} width={50} height={50} src={item?.businessOwner?.profileImage} alt="profile-image-err" />
            </div>
          ),
        };
      });


      const columnsOfOwnerBuisnessDetails = [
        {
          title: "Name",
          dataIndex: "Name",
          className: "role-name-column-header",
        },
        {
          title: "Email",
          dataIndex: "email",
          align: "center",
          className: "type-name-column-header",
          width: 400,
        },
        {
            title: "Buisness Address",
            dataIndex: "buisnessAddress",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Buisness Description",
          dataIndex: "buisnessdescription",
          align: "center",
          className: "action-column-header",
        },
        {
            title: "Contact",
            dataIndex: "buisnessNumber",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Profile Picture",
          dataIndex: "profilePicture",
          align: "center",
          className: "action-column-header",
        },
        {
          title: "Delievry Options",
          dataIndex: "delieveryOptions",
          align: "center",
          className: "action-column-header",
        },
      ];

      const dataOfOwnerBuisnessDetails = specificOrderDetails?.map((item, index) => {
        const deliveryoptions = item?.business?.youOffer;
        return {
          key: item?.business?._id,
          Name: item?.business?.businessName,
          email: item?.business?.email,
          buisnessAddress: (
            <div>
              {item?.business?.businessAddress && item?.business?.businessAddress.length > 3 ? (
                <p style={{ fontSize: "12px" }}>
                  {item?.business?.businessAddress.substring(0, 9) + "..."}
                  <span
                    onClick={() => {
                      setShowModalDes(true);
                      setPdescription(item?.business?.businessAddress);
                    }}
                    style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                  >
                    {" "}
                    See More{" "}
                  </span>
                </p>
              ) : null}
            </div>
          ),
          // item?.business?.businessAddress
          buisnessdescription: (
            <div>
              {item?.business?.businessDescription && item?.business?.businessDescription.length > 3 ? (
                <p style={{ fontSize: "12px" }}>
                  {item?.business?.businessDescription.substring(0, 9) + "..."}
                  <span
                    onClick={() => {
                      setShowModalDes(true);
                      setPdescription(item?.business?.businessDescription);
                    }}
                    style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                  >
                    {" "}
                    See More{" "}
                  </span>
                </p>
              ) : null}
            </div>
          ),
          
        // buisnessdescription: item?.businessDescription,
          buisnessNumber:item?.business?.phoneNumber,
          profilePicture: (
            <div className="product-list-image">
              <Image onClick={(e)=>e.stopPropagation()} width={50} height={50} src={item?.business?.workSpacePhoto}  />
            </div>
          ),
          delieveryOptions:(
            <div>
            <List
            dataSource={deliveryoptions}
            renderItem={(option, index) => (
            <List.Item key={index}>{option}</List.Item>
            )}
            bordered
            size="small"
            />
            </div>
          )
        };
      });


      const getRowClassName = (record, index) => {
        if (index % 2 === 0) {
          return "server-role-even-row";
        }
        return "server-role-odd-row";
      };
    

    return (
    <div
      className="add-product-modal-main-container"
    >
     {showModalDes && (
        <NestedDescription
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
     <Loader loading={isloading} />
      <div
        style={{ marginTop: "29rem", width: "140rem" }}
        className="add-product-modal-container product-description-detail"
      >
      <div style={{display:"flex",justifyContent:"space-between"}}>
        <h1>Client Details</h1>
        <button style={{backgroundColor:"#ffffff", border:"none",cursor: "pointer"}} onClick={() => setShowModal(false)}>
        <img src={crossIcon} alt="home-icon" />
        </button>
      </div>
        <h2>{item?.name}</h2>
        <h2>{item?.email}</h2>
      <div className="configure-server-roles-main-heading-container">
        <h1>Buisness Owner Details</h1> <div></div>
      </div> 
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>{e.stopPropagation()}}
          rowClassName={getRowClassName}
          columns={columnsOfOwnerDetails}
          dataSource={dataOfOwnerDetails}
          pagination={true}
          className="subscriptionapi-table-buisneessModal"
        ></Table>
      </div> 
      <div className="configure-server-roles-main-heading-container">
        <h1>Buisness Details</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>{e.stopPropagation()}}
          rowClassName={getRowClassName}
          columns={columnsOfOwnerBuisnessDetails}
          dataSource={dataOfOwnerBuisnessDetails}
          pagination={true}
          className="subscriptionapi-table-buisneessModal"
        ></Table>
      </div>
    </div>
    </div>
  );
};

export default OrderModal;

import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import Loader from "../../components/loader/loader";
import {
  homeIcon,
  orderIcon1,
  productIcon,
  productIcon1,
  redTrash,
  serviceIcon,
  serviceIcon1,
  userIcon1,
} from "../../assets";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import moment from "moment/moment";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import { useMemo } from "react";


const Dashboard = () => {
  const [value, setValue] = useState(new Date());
  const [isloading, setIsLoading] = useState(true);
  const [totalUsers,setTotalUsers] = useState('');
  const [totalProducts,setTotalProducts] = useState('');
  const [totalServices,setTotalServices] = useState('');
  const [totalProductOrders,setTotalProductOrders] = useState('');
  const [totalServiceOrders,setTotalServiceOrders] = useState('');
  const getAllUserData = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      // console.log("res of user list length", res?.data?.user?.length);
      setTotalUsers(res?.data?.user?.length)
      // setUsers(res?.data?.user);
      // chekDetails(res?.data?.data)
    };    
    callApi("GET", routes.getAllUser, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const getAllProductsorServices = () => {
    let getRes = (res) => {
      console.log("res of get products", res);
      let productf = res.data.products.filter(item=>item.objType=="product")
      console.log("all products without service",productf)
      setTotalProducts(productf?.length)
      let service = res.data.products.filter(item=>item.objType=="service")
      console.log("all services",service)
      setTotalServices(service?.length)
      // setProducts(res?.data?.products);
      // setShowModal(false);
    };

    callApi("GET", routes.getProducts, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };


  const getAllProductAndServicesOrders = () => {
    let getRes = (res) => {
      console.log("res of booked product order", res);
      // console.log("res of booked order service chek", res.data.orders[0].service.objType);
      let orderProduct = res.data.orders.filter(order=>order.type=="order")
      console.log("data cheking objeType product",orderProduct)
      setTotalProductOrders(orderProduct?.length);
      let orderService = res.data.orders.filter(order=>order.type=="booking")
      console.log("data cheking objeType service",orderService)
      setTotalServiceOrders(orderService?.length);
    };

    callApi(
      "GET",
      routes.productOrders,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };


  useMemo(() => {
    getAllUserData();
    getAllProductsorServices();
    getAllProductAndServicesOrders();
  }, [totalUsers,totalProducts,totalServices,totalProductOrders,totalServiceOrders]);



  const stateArr = [
    {
      title: "Total User",
      count: `${totalUsers}`,
      icon: userIcon1,
    },
    {
      title: "Total Product",
      count: `${totalProducts}`,
      icon: productIcon1,
    },
    {
      title: "Total Service",
      count: `${totalServices}`,
      icon: serviceIcon1,
    },
    {
      title: "Product Order",
      count: `${totalProductOrders}`,
      icon: orderIcon1,
    },
    {
      title: "Services Order",
      count: `${totalServiceOrders}`,
      icon: orderIcon1,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="admin-products-main-container">
     <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-main-container">
        <div className="dashboard-state-container">
          {stateArr.map((item) => (
            <div className="dashboard-state-count-container">
              <div className="dashboard-state-icon">
                <img src={item.icon} alt="icon" />
              </div>
              <div className="dashboard-state">
                <h2>{item.title}</h2>
                <p>{item.count}</p>
              </div>
            </div>
          ))}

          {/* <div className="dashboard-state-count-container"></div> */}
        </div>
        <div className="dashboard-pie-chart-container">
          <Clock size={120} value={value} />
          <p>
            Current time:{" "}
            <span style={{ color: "red", fontWeight: "700" }}>
              {moment(new Date()).format("DD, MMM, YYYY , HH:mm A")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

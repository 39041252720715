// import React from "react";
// import "./descriptionModal.css";

// const DescriptionModal = ({ showModalDes, setShowModalDes, description }) => {
//   return (
//     <div
//       onClick={() => setShowModalDes(false)}
//       className="add-product-modal-main-container"
//     >
//       <div
//         style={{ marginTop: "29rem", width: "70rem" ,  wordWrap:"break-word", }}
//         className="add-product-modal-container product-description-detail"
//       >
//         <h1>Description</h1>
//         <h2>{description}</h2>
//       </div>
//     </div>
//   );
// };

// export default DescriptionModal;



import React from "react";
import "./descriptionModal.css";



const DescriptionModal = ({ showModalDes, setShowModalDes, description }) => {
  
    // Set a minimum height for the modal
    const minHeight = 250;
    const maxHeight = 600;
   
    const descriptionHeight = description?.length > 250 ? maxHeight : minHeight 


    return (
    <div
      onClick={() => setShowModalDes(false)}
      className="add-product-modal-main-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        zIndex: 9999,
      }}
    >
      <div
        style={{
         marginLeft:"20rem",
         width: "800px", // Set the modal width to 600 pixels
        //   minHeight: `${minHeight + descriptionHeight}px`,
          height: descriptionHeight, // Set the modal height to 400 pixels
          overflowY: "auto", // Enable vertical scrolling when content overflows
        //   wordBreak: "break-word",
          wordWrap:"break-word",
          backgroundColor: "#fff", // White background color
          padding: "20px", // Add some padding to the modal content
          borderRadius: "8px", // Rounded corners
        }}
        className="add-product-modal-container product-description-detail"
      >
        <h1>Description</h1>
        <h2>{description?.length>0 ? (description) : (<h1 style={{
          display: "flex", // Use flexbox to center the h1 element
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}>No Data</h1>) }</h2>
      </div>
    </div>
  );
};

export default DescriptionModal;







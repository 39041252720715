import React from "react";
import "./productModal.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import { useEffect } from "react";
import { callApi } from "../../api/apiCaller";
import { useState } from "react";
import routes from "../../api/routes";
import moment from "moment/moment";
import Loader from "../loader/loader";
import DescriptionModal from "../descriptionBuisnessModal/descriptionModal";
import { crossIcon, homeIcon, redTrash, trueIcon } from "../../assets";
import { List } from 'antd';
import NestedDescription from "../nestedDescription/nestedDescription"
const ProductModal = ({ showModal, setShowModal, item, status }) => {
    console.log("items in modal page",item)
    // console.log("image",item?.profileImage?.props.children.props.src)

    const [isloading, setIsLoading] = useState(false);
    const [productbusiness,setProductbusiness] = useState([{}])
    const [showModalDes, setShowModalDes] = useState(false);
    const [pDescription, setPdescription] = useState('');


    // console.log("chek user buisness",buisnessofUser)

    const getProductBusiness = () => {
        let getRes = (res) => {
          console.log("res of product Business ", res);
          setProductbusiness(res?.data?.products)
        };
        callApi("GET", `${routes.productBusiness}/${item.key}`, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };


    useEffect(()=>{
      getProductBusiness()
    },[item.key])

    const columns = [
        {
          title: "Name",
          dataIndex: "Name",
          className: "role-name-column-header",
        },
        {
          title: "Email",
          dataIndex: "email",
          align: "center",
          className: "type-name-column-header",
          width: 400,
        },
        {
            title: "Buisness Address",
            dataIndex: "buisnessAddress",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Location",
          dataIndex: "locationAddress",
          align: "center",
          className: "action-column-header",
      },
        {
          title: "Buisness Description",
          dataIndex: "buisnessdescription",
          align: "center",
          className: "action-column-header",
        },
        {
            title: "Contact",
            dataIndex: "buisnessNumber",
            align: "center",
            className: "action-column-header",
        },
        {
          title: "Profile Picture",
          dataIndex: "profilePicture",
          align: "center",
          className: "action-column-header",
        },
        {
          title: "Delievry Options",
          dataIndex: "delieveryOptions",
          align: "center",
          className: "action-column-header",
        },
      ];


      console.log("productbusiness",productbusiness)

      const data = productbusiness?.map((item, index) => {
        const deliveryoptions = item?.business?.youOffer;
        return {
          key: item._id,
          Name: item?.business?.businessName,
          email: item?.business?.email,
          buisnessAddress: (
            <div>
              <p style={{ fontSize: "12px" }}>
                {item?.business?.businessAddress && item?.business?.businessAddress.length > 5
                  ? item?.business?.businessAddress.substring(0, 10) + "..."
                  : item?.business?.businessAddress}{" "}
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.business?.businessAddress);
                  }}
                  style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                >
                  {" "}
                  See More{" "}
                </span>
              </p>
            </div>
          ),
          // item?.business?.businessAddress,
          locationAddress:(
            <div>
              <p style={{ fontSize: "12px" }}>
                {item?.business?.locationText && item?.business?.locationText.length > 5
                  ? item?.business?.locationText.substring(0, 5) + "..."
                  : item?.business?.locationText}{" "}
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.business?.locationText);
                  }}
                  style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                >
                  {" "}
                  See More{" "}
                </span>
              </p>
            </div>
            // item?.locationText
          ),
          buisnessdescription: (
            <div>
              <p style={{ fontSize: "12px" }}>
                {item?.business?.businessDescription && item?.business?.businessDescription.length > 5
                  ? item?.business?.businessDescription.substring(0, 10) + "..."
                  : item?.business?.businessDescription}{" "}
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.business?.businessDescription);
                  }}
                  style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
                >
                  {" "}
                  See More{" "}
                </span>
              </p>
            </div>
          ),
        // buisnessdescription: item?.businessDescription,
          buisnessNumber:item?.business?.phoneNumber,
          profilePicture: (
            <div className="product-list-image">
              <Image onClick={(e)=>e.stopPropagation()} width={50} height={40} src={item?.business?.workSpacePhoto} alt="profile-image" />
            </div>
          ),
          delieveryOptions:(
            <div>
            <List
            dataSource={deliveryoptions}
            renderItem={(option, index) => (
            <List.Item key={index}>{option}</List.Item>
            )}
            bordered
            size="small"
            />
            </div>
          )
          // delieveryOptions: item?.youOffer?.map((ind,item)=>{
          //  return <li key={ind}>{ind}</li>
          // })
        };
      });

      const getRowClassName = (record, index) => {
        if (index % 2 === 0) {
          return "server-role-even-row";
        }
        return "server-role-odd-row";
      };


    return (
    <div
      className="add-product-modal-main-container"
    >
     {showModalDes && (
        <NestedDescription
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
     <Loader loading={isloading} />
      <div
        style={{ marginTop: "10rem", width: "150rem" }}
        className="add-product-modal-container product-description-detail"
      >
      <div style={{display:"flex",justifyContent:"space-between"}}>
        <h1>{status} Details</h1>
        <button style={{backgroundColor:"#ffffff", border:"none",cursor: "pointer"}} onClick={() => setShowModal(false)}>
        <img src={crossIcon} alt="home-icon" />
        </button>
      </div>
        <h2>{item?.title}</h2>
        <h2>{item?.price}</h2>    
      <div className="configure-server-roles-main-heading-container">
        <h1>{status} Buisness Detail</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>{e.stopPropagation()}}
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table-buisneessModal"
      ></Table>
      </div>
    </div>
    </div>
  );
};

export default ProductModal;

import React, { useState, useEffect } from "react";
import "./products.css";
import { Breadcrumb, Button, Select, Image } from "antd";
import { addIcon, editIcon, crossIcon, homeIcon, redTrash , trueIcon } from "../../assets";
import { Table } from "antd";
import ModalAddProduct from "../../components/ModalAddProduct/modalAddProduct";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import Loader from "../../components/loader/loader";
import { useDispatch } from "react-redux";
import { productItem } from "../../redux/userDataSlice";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";
import ProductModal from "../../components/productModal/productModal";
import moment from "moment/moment";

const Products = () => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [addProduct, setAddProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [getProduct, setGetProduct] = useState(false);
  const [produtStatus, setProductStatus] = useState('');

  
  const getProducts = () => {
    let getRes = (res) => {
      console.log("res of get products", res);
      // setProducts(res?.data?.products);
      // let service = res.data.products.filter(item=>item.objType=="service")
      // console.log("all servrvice in products",service)
      let productf = res.data.products.filter(item=>item.objType=="product")
      setProductStatus('Product')
      console.log("all products without service",productf)
      setProducts(productf)
      // setShowModal(false);
    };

    callApi("GET", routes.getProducts, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  
  let updateHST = async (ind, event, id, status) => {
    event.stopPropagation();
    let i = ind;
    console.log("id is", id, ind);
    let data = {
      id: id,
      chargeHST: status,
    };

     let getRes = (res) => {
      console.log("res of user list", res);
      if (res) {
        products[i] = res?.data?.user;
      }
    };

    console.log("sendingData", data);

     callApi(
      "PATCH",
      routes.updateProduct,
      data,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  
  };



  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      className: "role-name-column-header",
      width: 200,

    },
    {
      title: "Description",
      dataIndex: "description",
      className: "type-name-column-header",
      width: 200,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    // {
    //   title: "Duration",
    //   dataIndex: "duration",
    //   className: "action-column-header",
    //   width: 200,

    // },
    // {
    //   title: "Sale Price",
    //   dataIndex: "salePrice",
    //   align: "right",
    //   className: "action-column-header",
    // },
    // {
    //   title: "Price Type",
    //   dataIndex: "priceType",
    //   align: "center",
    //   className: "action-column-header",
    // },
    {
      title: "Price",
      dataIndex: "price",
      align: "center",
      className: "action-column-header",
      width: 200,

    },
    {
      title: "HST",
      dataIndex: "hstStatus",
      align: "center",
      className: "action-column-header",
      width: 200,
    },
    {
      title: "HST Amount",
      dataIndex: "hstAmount",
      align: "center",
      className: "action-column-header",
      width: 200,

    },
    // {
    //   title: "Deposit Required",
    //   dataIndex: "depositeRequired",
    //   align: "center",
    //   className: "action-column-header",
    // },
    // {
    //   title: "Deposit Amount",
    //   dataIndex: "depositeAmount",
    //   align: "center",
    //   className: "action-column-header",
    // },
    // {
    //   title: "Delievery Charges",
    //   dataIndex: "delieveryCharges",
    //   align: "center",
    //   className: "action-column-header",
    // },
    {
      title: "Image",
      dataIndex: "image",
      align: "center",
      className: "action-column-header",
      width: 200,
    },
    // {
    //   title: "Delete",
    //   dataIndex: "delete",
    //   align: "right",
    //   className: "action-column-header",
    // },
    // {
    //   title: "Edit",
    //   dataIndex: "edit",
    //   align: "right",
    //   className: "action-column-header",
    // },
  ];

  //   Row Data
  const data = products?.map((item, index) => {
    return {
      key: item._id,
      title: item?.name,
      description: (
        <div>
        <p style={{ fontSize: "12px" }}>
          {item?.description && item?.description.length > 1
            ? item?.description.substring(0, 3) + "..."
            : item?.description}{" "}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setShowModalDes(true);
              setPdescription(item?.description);
            }}
            style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
          >
            {" "}
            See More{" "}
          </span>
        </p>
      </div>
        ),
      // duration: `${item?.duration} mins`,
      // salePrice: `$${item?.salePrice ? item?.salePrice : item?.price}`,
      // priceType:item?.priceType,
      price: `$${item?.price}`,
      // depositeRequired: (
      //   <div className="server-roles-trash-btn">
      //     <img src={item?.depositRequired ? trueIcon : crossIcon} alt="" />
      //   </div>
      // ),
      // depositeAmount:item?.depositAmount,
      // delieveryCharges:item?.deliveryCharge,
      hstStatus:(
        <div className="server-roles-trash-btn">
          {item.chargeHST ? (
            <Button
              className="server-roles-trash-btn"
              style={{
                width: "80px",
                color:"white",
                backgroundColor: "#52c41a",
                // borderColor: "#f5222d",
              }}
              // style={{ width: '80px' }}
              // type="primary"
              onClick={async (event) => updateHST(index, event, item._id, false)}
            >
              Undo
            </Button>
          ) : (
            <Button
              className="server-roles-trash-btn"
              style={{
                width: "80px",
                color:"white",
                backgroundColor: "#f5222d",
                // borderColor: "#52c41a",
              }}
              // style={{ width: '80px' }}
              onClick={async (event) => updateHST(index, event, item?._id, true)}
            >
              Approve
            </Button>
          )}
        </div>
      ),
      hstAmount: item?.HSTAmount ? `${item?.HSTAmount} %` : "",
      image: (
        <div className="product-list-image">
          <Image onClick={(e)=> e.stopPropagation()} width={60} height={50} src={item?.images[0]?.url} />
        </div>
      ),
      // delete: (
      //   <div
      //     onClick={() => DeleteProduct(item)}
      //     className="server-roles-trash-btn"
      //   >
      //     <img src={redTrash} alt="" />
      //   </div>
      // ),
      // edit: (
      //   <div
      //     onClick={() => {
      //       setProduct(item);
      //       // dispatch(productItem(item));
      //       setShowModal(true);
      //       setAddProduct(false);
      //     }}
      //     className="product-list-edit-icon"
      //   >
      //     <img src={editIcon} />
      //   </div>
      // ),
    };
  });

  useEffect(() => {
    getProducts();
  }, [showModal, getProduct]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  const handleRowClick = (record) => {
    console.log("record", record)
    setShowModal(true);
    setProduct(record)
  };

  console.log("record object",product)

  return (
    <div className="admin-products-main-container">
      {/* {showModal && (
        <ModalAddProduct
          showModal={showModal}
          setShowModal={setShowModal}
          item={product}
          setIsLoading={setIsLoading}
          addProduct={addProduct}
          setAddProduct={setAddProduct}
        />
      )} */}

      {
    showModal && (
    <ProductModal 
          showModal={showModal}
          setShowModal={setShowModal}
          item={product} 
          status={produtStatus}
    />
    )
  }
      {showModalDes && (
        <DescriptionModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Products</h1>
        {/* <div
          onClick={() => {
            setAddProduct(true);
            setShowModal(true);
          }}
          className="server-roles-add-btn"
        >
          <img src={addIcon} alt="" />
          <p>Add New Product</p>
        </div> */}
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table-product"
          onRow={(record) => ({
          onClick: (e) => {
            e.stopPropagation();
            handleRowClick(record)},
          })}
        ></Table>
      </div>
    </div>
  );
};

export default Products;

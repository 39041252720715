import React, { useState, useEffect } from "react";
import "./productOrder.css";

import { Breadcrumb, Button, Select, Table, Image } from "antd";
import { crossIcon, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";
import OrderModal from "../../components/orderModal/orderModal";

const ProductOrder = () => {
  const [bookedProducts, setBookedProducts] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showModalDes, setShowModalDes] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [showModal,setShowModal] = useState(false);
  const getAllProductBooked = () => {
    let getRes = (res) => {
      console.log("res of booked product order", res);
      // console.log("res of booked order service chek", res.data.orders[0].type);
      let orderProduct = res.data.orders.filter(order=>order.type=="order")
      console.log("data cheking objeType product",orderProduct)
      setBookedProducts(orderProduct);
    };

    callApi(
      "GET",
      routes.productOrders,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  let paymentStatusUpdate = async (ind, event, id, payStatus) => {
    event.stopPropagation();
    let i = ind;
    console.log("id is", id, ind);
    let data = {
      id: id,
      paymentStatus: payStatus,
    };

     let getRes = (res) => {
      console.log("res of user list", res);
      if (res) {
        bookedProducts[i].paymentStatus = res?.data?.user?.paymentStatus;
      }
    };

    console.log("sendingData", data);

     callApi(
      "PATCH",
      routes.updateProductPaymentStatus,
      data,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  
  };
  

  // let paymentStatusUpdate = async (ind, event, id, payStatus) => {
  //   // event.stopPropagation()
  //   let i = ind;
  //   console.log("id is", id, ind);
  //   let data = {
  //     id: id,
  //     paymentStatus: payStatus,
  //   };

  //   // let getRes = (res) => {
  //   //   console.log("res of user list", res);
  //   //   if (res) {
  //   //     users[i] = res?.data?.user;
  //   //   }
  //   // };
  //   console.log("sendingData", data);

  //   //  callApi(
  //   //   "PATCH",
  //   //   routes.deleteUser,
  //   //   data,
  //   //   setIsLoading,
  //   //   getRes,
  //   //   (error) => {
  //   //     console.log("error", error);
  //   //   }
  //   // );
  // };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "name",
      className: "role-name-column-header",
    },
    {
      title: "Client Image",
      dataIndex: "profileImage",
      className: "role-name-column-header",
    },
    {
      title: "Client Email",
      dataIndex: "email",
      // align: "center",
      className: "type-name-column-header",
        width: 300,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Address",
      dataIndex: "address",
      align: "left",
      className: "type-name-column-header",
      width: 100,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      className: "action-column-header",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Price",
      dataIndex: "Price",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center",
      width: 20,
      className: "action-column-header",
    },
    {
      title: "IsPaid",
      dataIndex: "ispaid",
      align: "center",
      width: 20,
      className: "action-column-header",
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 150,
      className: "action-column-header",
      sorter: (a, b) =>
        moment(a.date, "DD-MM-YYYY").unix() -
        moment(b.date, "DD-MM-YYYY").unix(),
      // sorter: (a, b) => {
      //   console.log("date", a.date, moment(a.date, "DD-MM-YYYY").unix());
      // },s
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "action-column-header",
      
      filters: [
        {
          text: "New",
          value: "new",
        },
        {
          text: "Accepted",
          value: "accepted",
        },
        {
          text: "ReadyForPickup",
          value: "readyForPickup",
        },
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Decline",
          value: "decline",
        },
        {
          text: "Cancel",
          value: "cancel",
        },
        {
          text: "Delivered",
          value: "delivered",
        },
     
        
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];

  const data = bookedProducts?.map((item,ind) => {
    return {
      key: item._id,
      name: `${item?.customer?.name}`,
      date: moment(item?.createdAt).format("DD-MM-YYYY"),
      profileImage: (
        <div className="product-list-image">
          <Image onClick={(e)=>e.stopPropagation()} width={50} src={item?.customer?.profileImage} />
          {/* <img src={item?.user?.image} alt="profile-image" /> */}
        </div>
      ),
      email: item?.customer?.email,
      address:(
        <div>
          <p style={{ fontSize: "12px" }}>
            {item?.address && item?.address.length > 1
              ? item?.address.substring(0, 3) + "..."
              : item?.address}{" "}
            <span
              onClick={(e) => {
                e.stopPropagation();
                setShowModalDes(true);
                setPdescription(item?.address);
              }}
              style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
            >
              {" "}
              See More{" "}
            </span>
          </p>
        </div>
      ),
      //  item?.address,
      productName: item?.business?.businessName,
      orderNo: item?.recieptId,
      Price: `$${item?.amount}`,
      quantity: item?.quantity,
      ispaid: (
        <div className="server-roles-trash-btn">
          {item?.paymentStatus ? (
            <img onClick={(event) => paymentStatusUpdate(ind, event, item._id, false)} src={trueIcon} alt="Paid" />
          ) : (
            <img onClick={(event) => paymentStatusUpdate(ind, event, item._id, true)} src={crossIcon} alt="Unpaid" />
          )}
        </div>
      ),
      status: item?.status,
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  const handleRowClick = (record) => {
    console.log("record", record)
    setShowModal(true);
    // setSelectedRowKey(record.key);
    setSelectedOrder(record)
  };

  console.log("record object",selectedOrder)

  useEffect(() => {
    getAllProductBooked();
  }, []);

  return (
    <div className="admin-products-main-container">
      {
    showModal && (
    <OrderModal 
          showModal={showModal}
          setShowModal={setShowModal}
          item={selectedOrder} 
    />
    )
  }
  {showModalDes && (
        <DescriptionModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Product Order List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Products Order</h1> <div></div>
      </div>
      <div style={{width:'120%'}} className="server-roles-tb-main-container">
        <Table
          onClick={(e)=>e.stopPropagation()}
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          dropdownPrefixCls="vall"
          className="subscriptionapi-table-productOrder"
          onRow={(record) => ({
          onClick: (e) => {
            e.stopPropagation();
            handleRowClick(record)},
          })}
        ></Table>
      </div>
    </div>
  );
};

export default ProductOrder;
